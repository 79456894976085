// eslint-disable no-use-before-define
// eslint-disable react/prop-types
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  Button,
  makeStyles
} from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import {
  Calendar as CalendarIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, item, depth = 0 }) {
  const key = item.title + depth;
  acc.push(
    <NavItem
      depth={depth}
      href={item.href}
      icon={item.icon}
      key={key}
      info={item.info}
      title={item.title}
      enabled={item.enabled}
    />
  );

  return acc;
}

const imageLocation = process.env.REACT_APP_IMAGE;

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector(state => state.account);
  const navConfig = [
    {
      subheader: '',
      owner: user.roles[0].name,
      items: [
        {
          title: 'Dashboard',
          icon: PieChartIcon,
          href: '/app/reports/dashboard',
          enabled: true
        }
      ]
    },
    //administrator sub headers
    {
      subheader: 'Reports',
      owner: 'admin',
      items: [
        {
          title: 'Payments',
          icon: ListAltIcon,
          href: '/administrator/report/paynamics/payment',
          enabled: true
        },
        {
          title: 'List of Business Transaction',
          icon: ListAltIcon,
          href: '/administrator/request/clearances',
          enabled: true
        },
        {
          title: 'Users Administration',
          icon: ListAltIcon,
          href: '/administrator/users',
          enabled: true
        },
        {
          title: 'Requirements List',
          icon: ListAltIcon,
          href: '/administrator/requirements',
          enabled: true
        }
      ]
    },
    //assessor sub headers
    {
      subheader: 'Reports',
      owner: 'assessor',
      items: [
        {
          title: 'Request For Clearances',
          icon: ListAltIcon,
          href: '/assessor/request/clearances',
          enabled: true
        },
        {
          title: 'Holdings',
          icon: ListAltIcon,
          href: '/assessor/request/holdings/list',
          enabled: true
        }
      ]
    },
    //engineering sub headers
    {
      subheader: 'Reports',
      owner: 'engineering',
      items: [
        {
          title: 'Request For Clearances',
          icon: ListAltIcon,
          href: '/engineering/request/clearances',
          enabled: true
        },
        {
          title: 'Holdings',
          icon: ListAltIcon,
          href: '/engineering/request/holdings/list',
          enabled: true
        }
      ]
    },
    //fire sub headers
    {
      subheader: 'Reports',
      owner: 'fire',
      items: [
        {
          title: 'Request For Clearances',
          icon: ListAltIcon,
          href: '/fire/request/clearances',
          enabled: true
        },
        {
          title: 'Holdings',
          icon: ListAltIcon,
          href: '/fire/request/holdings/list',
          enabled: true
        }
      ]
    },
    //mao sub headers
    {
      subheader: 'Reports',
      owner: 'mao',
      items: [
        {
          title: 'Request For Clearances',
          icon: ListAltIcon,
          href: '/mao/request/clearances',
          enabled: true
        },
        {
          title: 'Holdings',
          icon: ListAltIcon,
          href: '/mao/request/holdings/list',
          enabled: true
        }
      ]
    },
    //menro sub headers
    {
      subheader: 'Reports',
      owner: 'menro',
      items: [
        {
          title: 'Request For Clearances',
          icon: ListAltIcon,
          href: '/menro/request/clearances',
          enabled: true
        },
        {
          title: 'Holdings',
          icon: ListAltIcon,
          href: '/menro/request/holdings/list',
          enabled: true
        }
      ]
    },
    //mpdo sub headers
    {
      subheader: 'Reports',
      owner: 'mpdo',
      items: [
        {
          title: 'Request For Clearances',
          icon: ListAltIcon,
          href: '/mpdo/request/clearances',
          enabled: true
        },
        {
          title: 'Holdings',
          icon: ListAltIcon,
          href: '/mpdo/request/holdings/list',
          enabled: true
        }
      ]
    },
    //rhu sub headers
    {
      subheader: 'Reports',
      owner: 'rhu',
      items: [
        {
          title: 'Request For Clearances',
          icon: ListAltIcon,
          href: '/rhu/request/clearances',
          enabled: true
        },
        {
          title: 'Holdings',
          icon: ListAltIcon,
          href: '/rhu/request/holdings/list',
          enabled: true
        }
      ]
    },
    //peso sub headers
    {
      subheader: 'Reports',
      owner: 'peso',
      items: [
        {
          title: 'Request For Clearances',
          icon: ListAltIcon,
          href: '/peso/request/clearances',
          enabled: true
        },
        {
          title: 'Holdings',
          icon: ListAltIcon,
          href: '/peso/request/holdings/list',
          enabled: true
        }
      ]
    },

    //barangay sub headers
    {
      subheader: 'Reports',
      owner: user.roles[0].name.includes('barangay') ? 'barangay' : '',
      items: [
        {
          title: 'Request For Clearances',
          icon: ListAltIcon,
          href: '/barangay/request/clearances',
          enabled: true
        }
      ]
    },

    //bplo sub headers
    {
      subheader: 'Reports',
      owner: 'bplo',
      items: [
        {
          title: 'Request For Clearances',
          icon: ListAltIcon,
          href: '/bplo/request/clearances',
          enabled: true
        },
        {
          title: 'Holdings',
          icon: ListAltIcon,
          href: '/bplo/request/holdings/list',
          enabled: true
        }
      ]
    },

    {
      // users subheader
      subheader: 'Reports',
      owner: 'user',
      items: [
        {
          title: 'Business Holdings',
          icon: ListAltIcon,
          href: '/app/management/companies',
          enabled: true
        },
        {
          title: 'Payment History',
          icon: ListAltIcon,
          href: '/app/management/companies/payment/history',
          enabled: true
        },
        {
          title: 'Statement of Account',

          icon: ListAltIcon,
          href: '/app/management/companies/account/soa',
          enabled: true
        }
      ]
    },
    {
      subheader: 'Applications',
      owner: user.roles[0].name,
      items: [
        {
          title: 'Mail',
          href: '/app/mail',
          icon: MailIcon,
          enabled: false,
          info: () => <Chip color="secondary" size="small" label="Updated" />
        },
        {
          title: 'Chat',
          href: '/app/chat',
          icon: MessageCircleIcon,
          enabled: false,
          info: () => <Chip color="secondary" size="small" label="Updated" />
        },
        {
          title: 'Calendar',
          href: '/app/calendar',
          icon: CalendarIcon,
          enabled: false
        }
      ]
    }
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={`${imageLocation}avatar/${user.avatar}`}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.firstname} ${user.lastname}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.bio}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config, index) => (
            <List
              style={{
                display:
                  config.owner === user.roles[0].name
                    ? 'block'
                    : config.owner === 'barangay'
                    ? 'd-block'
                    : 'none'
              }}
              key={index}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Hidden xsUp xsDown>
          <Box p={1}>
            <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
              <Typography variant="h6" color="textSecondary" align="center">
                To register your property? Click{' '}
              </Typography>

              <Box mt={1}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to="/app/property/register"
                  fullWidth
                >
                  {' '}
                  Register{' '}
                </Button>
              </Box>
            </Box>
          </Box>
        </Hidden>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
