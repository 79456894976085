import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
// import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 200,
    paddingBottom: 200,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      maxWidth: '400px',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  }
}));

function Hero({ className, ...rest }) {
  const classes = useStyles();
  // const history = useHistory()
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography variant="overline" color="textSecondary">
                Introducing
              </Typography>
              <Typography variant="h1" color="textPrimary">
                Hybrid Business Permit and Licensing System (eBPLS)
              </Typography>
              <Box mt={3}>
                <Typography variant="body1" color="textSecondary">
                  Makabagong paraan ng pag-babayad at pag-rehistro ng mga
                  may-ari ng establisyemento sa bayan ng TIWI gamit ang
                  smartphone.
                  {''}
                </Typography>
                <Box mt={3} />
                <Box />
                <Typography variant="body1" color="textSecondary">
                  {' '}
                </Typography>
              </Box>
              <Box mt={3}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Typography variant="h4" color="textPrimary">
                      Reporma at Tagumpay Bayan ng TIWI
                    </Typography>

                    {/* <Box mt={4} align="center">
                      <Button variant="contained" color="primary" href="">
                        Paano Magbayad at Magparehistro
                      </Button>
                    </Box> */}

                    <Box mt={4} align="center">
                      <Button
                        variant="contained"
                        color="primary"
                        href=""
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_IMAGE}attachments/forms/business-permit-form.xlsx`,
                            '_blank'
                          );
                        }}
                      >
                        DOWNLOAD BUSINESS PERMIT FORM
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box position="relative">
              <div className={classes.shape}>
                <img alt="Shapes" src="/static/home/shapes.svg" />
              </div>
              <div className={classes.image}>
                <img alt="Presentation" src="/static/home/dark-light.png" />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
